<template>
  <div :class="['q-page-left document', { 'disabled-blur': getPlacedStatus }]">
    <AddEntityTemplate
      :is-show="showModalAddEntity"
      @closeModal="closeModalAddEntity"
      @middle-open-panel="openMiddlePanel"
      :content="getEntityList"
      :title="'Add an entity'"
      :placeholder="'La nouvelle entité sera :'"
    />
    <SaveEntityModal
      :is-show="showModalSaveEntity"
      :content="getNameForSaveModal"
      @closeModal="closeModalSaveEntity"
      @discardChanges="cancelEntity"
      @saved="saveEntityLocal"
    />
    <DeleteModalTemplate
      :is-show="showModalDeleteEntity"
      :content="getNameForSaveModal"
      @closeModal="closeModalDeleteEntity"
      @removed="_deleteEntity"
    />
    <UnsavedChanges
      :isShow="getShowUnsavedChanges.status"
      :content="content"
      @closeModal="closeModalUnsavedChanges"
      @discardChanges="cancelUnsavedChanges"
      @saved="saveUnsavedChanges"
    />
    <div v-if="getPlacedStatus" class="blur-placer__uniq"></div>
    <div
      :class="!getPlacedStatus ? 'actions-side' : 'actions-side disabled-blur'"
    >
      <template v-if="!showListEnity">
        <q-btn
          class="btn-new"
          icon-right="add"
          label="Ajouter une entité"
          @click="openModalAddEntity"
        />
      </template>
      <template v-else>
        <q-btn class="btn-cancel" label="Annuler" @click="openModalSaveEntity" />
        <q-btn class="btn-save" label="Sauvegarder" @click="saveEntityLocal()">
          <q-icon class="save-icon" />
        </q-btn>
        <q-btn
          v-if="getEdit && !getTypeShort"
          label="Delete"
          class="btn-trash"
          @click="openModalDeleteEntity"
        >
          <q-icon class="trash-icon" />
        </q-btn>
      </template>
    </div>
    <div class="entity-list">
      <div
        class="item entity-item"
        v-for="(item, index) in getEntityList"
        :key="index"
      >
        <div
          :class="[
            'item',
            'entity-item',
            'item-header',
            { blur: getPlacedStatus }
          ]"
        >
          <img
            @click="toggleAccordion(item)"
            :class="[
              'accordion',
              { 'accordion-close': accordions.includes(item.id) }
            ]"
            src="../../assets/icons/icon-arrow-down.svg"
            alt=""
          />
          <span @click="toggleAccordion(item)">
            {{ item.name || item.entityLabel }}
          </span>
          <button
            v-if="item.status === 'read-only' && getEntityStatus !== 'add'"
            class="edit-btn__custom"
            @click="_editEntity(index)"
          >
            <img src="../../assets/icons/edit.svg" alt="" />
          </button>
        </div>
        <div :class="[{ hidden: accordions.includes(item.id) }]">
          <div class="empty empty-entity" v-if="!item.fields.length">
            <img src="../../assets/icons/empty-entity.svg" />
            <div>No items have been chosen yet</div>
            <p>
              Choose the information items that are required for this entity in
              this template from the list on the right.
            </p>
          </div>
          <template
            v-if="
              item.fields.length && item.fields[0] && (item.id == '' || item.id == null)
            "
          >
            <div
              :class="
                getTypeShort && item.status === 'read-only'
                  ? 'item-body item-body-blur'
                  : 'item-body'
              "
            >
              <!-- If less than 3 items the hint text is too long -->
              <div v-if="item.fields.length <= 2">
                <!-- If less than 2 items the blur zone is too small -->
                <div v-if="item.fields.length == 1">
                  <div
                    v-if="item.status === 'read-only' && getTypeShort"
                    class="blur-placer"
                    style="padding-top: 5px;"
                  >
                    <p>
                      Cliquez sur le bouton &nbsp; <img src="../../assets/icons/edit.svg" alt="" />
                    </p>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-if="item.status === 'read-only' && getTypeShort"
                    class="blur-placer"
                  >
                    <p>
                      Cliquez sur le bouton &nbsp; <img src="../../assets/icons/edit.svg" alt="" />
                    </p>
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                    v-if="item.status === 'read-only' && getTypeShort"
                    class="blur-placer"
                  >
                    <p>
                      Cliquez sur le bouton <img src="../../assets/icons/edit.svg" alt="" /> pour choisir quelle entité du projet doit remplacer celle-ci.
                    </p>
                  </div>
              </div>

              <div
                v-for="(field, index_field) in item.fields"
                :key="index_field"
                :data-field="
                  !getPlacedStatus && !field.placedStatus
                    ? ''
                    : 'Cliquer sur le document pour définir l’emplacement de cette information'
                "
                :class="
                  !getPlacedStatus && !field.placedStatus
                    ? 'item-field'
                    : getPlacedStatus && !field.placedStatus
                    ? 'item-field disabled-blur'
                    : 'item-field'
                "
              >
                <q-tooltip
                  v-if="
                    !getPlacedStatus &&
                      isShowTooltip(`${item.name} - ${field.dataLabel}`)
                  "
                >
                  {{ item.name }} - {{ field.dataLabel }}</q-tooltip
                >
                <div class="item-field-content">
                  <span class="item-field-content__title">
                    {{ item.name }} - {{ field.dataLabel }}
                  </span>
                </div>
                <button
                  @click="removeFromSelected(index_field)"
                  class="item-field-action"
                  v-if="item.status === 'edit'"
                >
                  <i
                    aria-hidden="true"
                    role="presentation"
                    class="material-icons q-icon notranslate"
                    >close</i
                  >
                </button>
                <q-btn
                  v-else
                  label="place"
                  v-show="!getPlacedStatus"
                  @click="selectPlacer(index, index_field, item, field)"
                ></q-btn>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              :class="[
                'item-body',
                { 'item-body-edit': item.status === 'edit' }
              ]"
            >
              <div
                :class="[
                  'item-field',
                  {
                    blur: field.placedStatus !== true,
                    'item-field__focused': field.editable
                  }
                ]"
                v-for="(field, index_field) in item.fields"
                :key="index_field"
              >
                <q-tooltip
                  v-if="!field.editable && isShowTooltip(`${field.dataLabel}`)"
                >
                  {{ field.dataLabel }}</q-tooltip
                >
                <div :class="['item-field-content item-field-content__custom']">
                  <div v-if="field.locked === true">
                    <span
                      class="lock"
                    ></span>
                  </div>
                  <div v-else>
                    <span
                      v-if="field.dataConfidence === 'LOW'"
                      class="low"
                    ></span>
                    <span
                      v-else-if="field.dataConfidence === 'HIGH'"
                      class="high"
                    ></span>
                    <span
                      v-else-if="field.dataConfidence"
                      class="manual"
                    ></span>
                  </div>

                  <div v-if="field.dataValue !== ''" class="item-field-content">
                    <span
                      class="item-field-content__title item-field-content__title--combine"
                    >
                      {{ field.dataLabel ? field.dataLabel : field.text }}
                    </span>
                    <span
                      v-if="!field.editable || field.isPlaced !== false"
                      class="item-field-content__sub-title"
                    >
                      {{ field.dataValue }}
                    </span>
                    <!-- <span
                      class="item-field-content__sub-not-place"
                      v-if="field.isPlaced === false"
                    >
                      Not placed yet
                    </span> -->
                  </div>
                  <div v-else class="item-field-content">
                    <span class="item-field-content__title">
                      {{ item.name }} -
                      {{ field.dataLabel ? field.dataLabel : field.text }}
                    </span>
                    <span
                      v-if="!field.editable || field.isPlaced !== false"
                      class="item-field-content__sub-title"
                    >
                      {{ field.dataValue }}
                    </span>
                  </div>
                </div>
                <div class="note" v-if="field.placedStatus">
                  Cliquer sur le document pour définir l’emplacement de cette information.
                </div>
                <div class="input-hidden" v-if="field.editable">
                  <textarea
                    v-model="editPlaceholderValue"
                    class="editor-placeholder"
                  />
                  <label class="input-hidden__actions">
                    <q-btn
                      class="btn-cancel"
                      label="Annuler"
                      @click="editEtityPlaceholder(item, field, false)"
                    />
                    <q-btn
                      class="btn-save"
                      label="Sauvegarder"
                      @click="saveEditedEntityPlaceholder(item, field, false)"
                    >
                      <q-icon class="save-icon" />
                    </q-btn>
                  </label>
                </div>
                <button
                  @click="removeFromSelected(index_field)"
                  class="item-field-action"
                  v-if="item.status === 'edit'"
                >
                  <i
                    aria-hidden="true"
                    role="presentation"
                    class="material-icons q-icon notranslate"
                    >close</i
                  >
                </button>
                <template v-else>
                  <img
                    v-if="
                      !field.editable &&
                        !getPlacedStatus &&
                        getEntityStatus !== 'add'
                    "
                    class="edit-btn__custom"
                    @click="editEtityPlaceholder(item, field, true)"
                    src="../../assets/icons/edit-blue.svg"
                    alt=""
                  />
                  <q-btn
                    v-if="getTrueDocumentType == 'document'"
                    field.editable
                    label="Placer"
                    v-show="!getPlacedStatus && getEntityStatus !== 'add'"
                    @click="selectPlacer(index, index_field, item, field)"
                  ></q-btn>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="getPlacedStatus" class="placed-actions">
      <q-btn class="btn-cancel" label="Annuler" @click="resetPlaced" />
      <q-btn
        v-if="getDocFocused"
        class="btn-new"
        label="Confirmer"
        @click="confirmSelectEntityElement"
      />
    </div>
    <div class="empty" v-if="!getEntityList.length">
      <img src="../../assets/icons/add-entities.svg" />
      <div>Pas d’entité créée</div>
      <p>
        Ajoutez une entité (partie au contrat) en cliquant sur “Ajouter une entité” et
        sélectionnez l’ensemble des informations que vous souhaitez voir apparaitre dans le modèle. 
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AddEntityTemplate from '@/components/modals/AddEntityTemplate'
import SaveEntityModal from '@/components/modals/SaveEntityTemplate'
import DeleteModalTemplate from '@/components/modals/DeleteEntitytTemplate'
import UnsavedChanges from '@/components/modals/UnsavedChanges'
import isEqual from 'lodash.isequal'

export default {
  name: 'LeftPanel',
  components: {
    AddEntityTemplate,
    SaveEntityModal,
    DeleteModalTemplate,
    UnsavedChanges
  },
  props: {
    place: {
      type: Object,
      default: () => {}
    },
    modelId: {
      type: String,
      default: ''
    },
    reportId: {
      type: String,
      default: ''
    },
    file: {
      type: Object,
      default: () => {}
    },
    trueDocumentType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showListEnity: false,
      addEntityState: false,
      saveEntityModal: false,
      deleteEntityModal: false,
      editPlaceholderValue: '',
      dataPlaced: [],
      selectEntity: {},
      accordions: [],
      content: {
        title: 'Sauvegarder les changements',
        description: 'Souhaitez-vous enregistrer les modifications apportées à ce document avant de quitter la page ?'
      }
    }
  },
  async created() {
    this.setEntityList([])
    this.fetchListPlaceholders()
    if (this.getTypeShort && this.getReportId) {
      await this.$store
        .dispatch('templates/fetchProjectId', this.getReportId)
        .then(val => {
          this.setReportDataResponse(val)
          this.setFetchEntityList(val)
          this.setReportName(val.reportName)
          this.fetchReplaceEntities(val.projectId)
        })
    }

    if (this.getModelId !== '') {
      await this.fetchListEntity(this.getModelId)
    }

    const getEntityList = [...this.getEntityList].map(entity => {
      return {
        ...entity,
        fields: entity.fields.map(field => {
          delete field.template
          delete field.editable
          return {
            ...field
          }
        })
      }
    })
    this.setEntityListDublicate(getEntityList)
  },
  computed: {
    ...mapGetters('templates', [
      'getShowNewFields',
      'getEdit',
      'getEntityList',
      'getSingleEntity',
      'getDocumentType',
      'newReportData',
      'getReportDataResponse',
      'getReportName',
      'getEntityId',
      'getOldLabel',
      'getEntityListDublicate',
      'getPlacedStatus',
      'getDocFocused',
      'getEntityStatus',
      'getShowUnsavedChanges'
    ]),
    getSelectEntity() {
      return this.selectEntity
    },
    getModelId() {
      return this.modelId
    },
    showModalAddEntity() {
      return this.addEntityState
    },
    showModalSaveEntity() {
      return this.saveEntityModal
    },
    showModalDeleteEntity() {
      return this.deleteEntityModal
    },
    getNameForSaveModal() {
      return this.getSingleEntity.name
    },
    getTypeShort() {
      return this.getDocumentType === 'document'
    },
    getTrueDocumentType() {
      return this.trueDocumentType
    },
    getReportId() {
      return this.reportId
    },
    getPlacedData() {
      return this.dataPlaced
    },
    getPutListEntity() {
      const newArr = {
        reportName: this.getReportName,
        reportData: []
      }
      for (const entity of this.getEntityList) {
        const editedEntity = {}
        editedEntity.entityCode = !entity.type
          ? entity.code || entity.name
          : this.getOldLabel.toUpperCase()
        editedEntity.entityLabel = !entity.type
          ? entity.label || entity.name
          : this.getOldLabel
        editedEntity.entityValue = entity.name
        editedEntity.entityId = entity.id || ''
        editedEntity.entityData = []

        for (const prop of entity.fields) {
          const newObj = { ...prop }
          newObj.dataCode = prop.dataCode || ''
          newObj.dataLabel = prop.dataLabel || prop.text
          newObj.dataValue = prop.dataValue || ''
          newObj.dataConfidence = prop.dataConfidence || 'MANUAL'
          newObj.dataId = prop.dataId || ''
          newObj.locked = prop.locked
          editedEntity.entityData.push(newObj)
        }
        newArr.reportData.push(editedEntity)
      }
      return newArr
    }
  },
  methods: {
    ...mapActions('templates', [
      'setEntityList',
      'setS3Url',
      'getS3url',
      'setPlacedStatus',
      'setPlacedStatusForField',
      'setFocusedDocument',
      'setReportDataResponse',
      'setFetchEntityList',
      'setReportName',
      'fetchReplaceEntities',
      'fetchListEntity',
      'clearSingleEntity',
      'clearStateEdit',
      'fetchReportData',
      'saveEntity',
      'deleteEntity',
      'setEditableStateForPlaceholder',
      'setShowNewFields',
      'removeSelected',
      'setOldLabel',
      'editEntity',
      'fetchUpgradeEntity',
      'putReportUpload',
      'fetchMailMerge',
      'setEditableDataForPlaceholder',
      'fetchListPlaceholders',
      'setEntityOldList',
      'setEntityListDublicate',
      'setShowUnsavedChanges'
    ]),
    toggleAccordion(item) {
      const accordionIndex = this.accordions.findIndex(
        accordion => accordion === item.id
      )
      if (accordionIndex !== -1) {
        const accordions = this.accordions.filter(
          accordion => accordion !== item.id
        )
        this.accordions = accordions
      } else {
        this.accordions.push(item.id)
      }
    },
    confirmSelectEntityElement() {
      this.selectEntityElement(
        this.getSelectEntity.item,
        this.getSelectEntity.field
      )
      this.resetPlaced()

      // Uncomment this to mailmerge after Place data
      // this.$emit(
      //   'getFileNameMailmerge',
      //   this.name
      // )
    },
    resetPlaced() {
      if (this.getPlacedData && this.getPlacedData.length) {
        this.setPlacedStatusForField([
          this.getPlacedData[0],
          this.getPlacedData[1],
          false
        ])
      }
      this.setPlacedStatus(false)
      this.setFocusedDocument(false)
      this.getSelectEntity.item = {}
      this.getSelectEntity.field = {}
    },
    saveEditedEntityPlaceholder(el, field) {
      const indexEntity = this.getEntityList.indexOf(el)
      const indexPlaceholder = this.getEntityList[indexEntity].fields.indexOf(
        field
      )
      const val = this.editPlaceholderValue
      this.setEditableDataForPlaceholder([indexEntity, indexPlaceholder, val])
      this.editPlaceholderValue = null
      const entityList = {
        ...this.getPutListEntity,
        reportData: this.getPutListEntity.reportData.map(report => {
          return {
            entityCode: report.entityCode,
            entityLabel: report.entityLabel,
            entityValue: report.entityValue,
            entityId: report.entityId,
            entityData: report.entityData.map(entity => {
              const _entity = {
                dataCode: entity.dataCode,
                dataConfidence: entity.dataConfidence,
                dataId: entity.dataId,
                dataLabel: entity.dataLabel,
                dataValue: entity.dataValue,
                dataYear: entity.dataYear,
                locked: entity.locked
              }
              return _entity
            })
          }
        })
      }

      this.$store
        .dispatch('templates/fetchUpgradeEntity', [
          this.getReportId,
          entityList
        ])
        .then(() => {
          // this.$store
          //   .dispatch('templates/fetchMailMerge', this.getReportId)
          //   .then(i => {
          //     this.setS3Url({
          //       type: this.file.typeShort,
          //       s3Url: i.s3Url,
          //       s3UrlOriginal: i.s3UrlOriginal,
          //       name: this.file.name
          //     })
          //   })
          this.$emit('getFileNameMailmerge', this.name)
        })
    },
    editEtityPlaceholder(el, field, flag) {
      const indexEntity = this.getEntityList.indexOf(el)
      const indexPlaceholder = this.getEntityList[indexEntity].fields.indexOf(
        field
      )
      if (flag) {
        this.editPlaceholderValue = field.dataValue
      } else {
        this.editPlaceholderValue = null
      }
      this.setEditableStateForPlaceholder({
        indexEntity,
        indexPlaceholder,
        flag
      })
    },
    async cancelEntity() {
      this.setEntityOldList(this.getEntityListDublicate)
      this.showListEnity = false
      this.$emit('openMiddlePanel')
      this.clearSingleEntity()
      this.closeModalSaveEntity()
    },
    _editEntity(index) {
      if (!this.getEdit) {
        this.showListEnity = true
        this.$emit('openMiddlePanel')
        this.editEntity(index)
        this.setOldLabel(
          this.getEntityList[index].dataLabel || this.getEntityList[index].name
        )
      }
    },
    closeModalDeleteEntity() {
      this.deleteEntityModal = false
    },
    openModalDeleteEntity() {
      this.deleteEntityModal = true
    },
    closeModalSaveEntity() {
      this.saveEntityModal = false
    },
    openModalSaveEntity() {
      if (this.getEntityListDublicate && this.getEntityListDublicate.length) {
        const _isEqual = isEqual(
          this.getEntityList,
          this.getEntityListDublicate
        )
        if (!_isEqual) {
          this.saveEntityModal = true
        } else {
          this.setEntityOldList(this.getEntityListDublicate)
          this.showListEnity = false
          this.$emit('openMiddlePanel')
          this.clearSingleEntity()
          this.closeModalSaveEntity()
        }
      } else {
        this.saveEntityModal = true
      }
    },
    closeModalAddEntity() {
      this.addEntityState = false
    },
    openModalAddEntity() {
      this.addEntityState = true
    },
    openMiddlePanel() {
      this.showListEnity = true
      this.$emit('openMiddlePanel')
      this.closeModalAddEntity()
    },
    _deleteEntity() {
      this.closeModalDeleteEntity()
      this.$emit('openMiddlePanel')
      this.showListEnity = false
      this.deleteEntity()
      this.saveEntity()
    },
    async saveEntityLocal() {
      if (
        this.getTypeShort &&
        this.getSingleEntity.fields.length
        //&&
        // !this.getSingleEntity.fields[0].template
      ) {
        const reportData = this.getPutListEntity.reportData.map(report => {
          const entityData = report.entityData.map(entity => {
            const newEntity = {
              dataYear: entity.dataYear,
              dataCode: entity.dataCode,
              dataId: entity.dataId,
              dataLabel: entity.dataLabel || entity.text,
              dataValue: entity.dataValue,
              dataConfidence: entity.dataConfidence || 'MANUAL',
              locked: entity.locked
            }

            if (entity.dataValue !== null && entity.dataValue !== undefined) {
              // newEntity.locked = false
              // newEntity.locked = true
              // newEntity.dataConfidence = 'LOW/HIGH/MANUAL'
            } else {
              newEntity.dataYear = ''
              newEntity.dataValue = ''
              newEntity.dataId = ''
              newEntity.locked = false
              // newEntity.dataConfidence = 'LOW/HIGH/MANUAL'
            }
            return newEntity
          })
          return {
            ...report,
            entityData
          }
        })

        this.fetchUpgradeEntity([
          this.getReportId,
          {
            reportName: this.getPutListEntity.reportName,
            reportData
          }
        ]).then(async () => {
          this.$emit('getFileNameMailmerge', this.name)
        })
      }
      this.saveEntity(!this.showNewFields)

      this.setShowNewFields(false)

      this.$emit('openMiddlePanel')

      this.showListEnity = false

      this.closeModalSaveEntity()
    },
    removeFromSelected(index) {
      this.removeSelected(index)
      if (this.getShowNewFields) {
        const entityList = {
          ...this.getPutListEntity,
          reportData: this.getPutListEntity.reportData.map(report => {
            return {
              entityCode: report.entityCode,
              entityData: report.entityData.map(entity => {
                const _entity = {
                  dataCode: entity.dataCode,
                  dataConfidence: entity.dataConfidence || 'MANUAL',
                  dataId: entity.dataId,
                  dataLabel: entity.dataLabel,
                  dataValue: entity.dataValue,
                  dataYear: entity.dataYear,
                  locked: entity.locked
                }
                return _entity
              })
            }
          })
        }
        this.fetchUpgradeEntity([this.getReportId, entityList])
      }
    },
    selectEntityElement(entity, element) {
      this.$emit('entityElementForDocument', { entity, element })
    },
    selectPlacer(index, indexField, item, field) {
      this.selectEntity.item = item
      this.selectEntity.field = field
      this.dataPlaced = [index, indexField]
      this.setPlacedStatus(true)
      this.setPlacedStatusForField([index, indexField, true])
    },
    isShowTooltip(title) {
      if (title.length > 40) {
        return true
      }
      return false
    },
    saveUnsavedChanges() {
      this.$emit('saveUnsavedChanges')
    },
    cancelUnsavedChanges() {
      this.setEntityOldList(this.getEntityListDublicate)
      this.showListEnity = false
      this.clearSingleEntity()
      const payload = this.getShowUnsavedChanges.payload
      this.setShowUnsavedChanges({ status: false, payload: {} })
      payload.next()
    },
    closeModalUnsavedChanges() {
      this.setShowUnsavedChanges({ status: false, payload: {} })
    }
  },
  watch: {
    'getSingleEntity.name': function() {
      if (this.getSingleEntity.code == undefined | this.getSingleEntity.dataId == '') return
      this.fetchReportData({
        reportId: this.reportId,
        entityCode: this.getSingleEntity.code,
        entityId: this.getSingleEntity.id
      })
    },
    place: function(data) {
      if (Object.keys(data).length) {
        const result = this.getEntityList.map(list => {
          const _list = { ...list }
          const index = list.fields?.findIndex(field => {
            return (
              (field.code === data.data.element.code ||
                field.dataCode === data.data.element.dataCode) &&
              list.id === data.data.entity.id &&
              field.dataYear === data.data.element.dataYear
            )
          })

          if (data.page && index !== -1) {
            const field = list.fields[index]
            const pages = [...(field.pages || []), data.page]

            _list.fields[index] = {
              ...field,
              isPlaced: true,
              dataCode: list.fields[index].dataCode,
              pages
            }
          }

          if (data.position && index !== -1) {
            const field = list.fields[index]
            const pages = [...(field?.pages || []), data.position]
            _list.fields[index] = {
              ...field,
              isPlaced: true,
              dataCode: list.fields[index].dataCode,
              pages
            }
          }

          return _list
        })

        this.setEntityList(result)
      }
    }
  },
  filters: {
    join(arr) {
      return arr.join(', ')
    }
  },
  beforeDestroy() {
    this.setShowUnsavedChanges({ status: false, payload: {} })
    this.resetPlaced()
  },
  destroyed() {
    this.clearStateEdit()
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 600px) {
  .q-dialog .q-card {
    max-width: 418px;
  }
}
</style>
