<template>
  <div
    id="step-two"
    :class="{ 'step-two': true, 'open-middle-panel': isOpenMiddlePanel }"
  >
    <q-page-container>
      <q-page>
        <LeftPanel
          :report-id="file.reportId"
          :file="file"
          @getFileNameMailmerge="getFileNameMailmerge"
          @openMiddlePanel="openMiddlePanel"
          @entityElementForDocument="entityElementForDocument"
          :place.sync="place"
          :model-id="getModelId"
          @saveUnsavedChanges="saveUnsavedChanges"
          :trueDocumentType="typeShort"
        />
        <MiddlePanel v-if="isOpenMiddlePanel" />
        <div class="q-page-right">
          <TemplateName
            @onSubmit="onSubmitMain"
            @getFileName="getFileName"
            :getName.sync="getName"
            :is-edit="isEdit"
            :is-download="isDownload"
            :is-report="isReport"
            :file="file"
            :isSubmit.sync="isSubmit"
            @nameFileChanged="nameChanged"
          />
          <DocumentEditor
            v-if="typeShort === 'document'"
            :file="file"
            :reportId="reportId"
            :getData.sync="getData"
            @defaultData="defaultData"
            :getDataMailmerge.sync="getDataMailmerge"
            @actualData="actualData"
            @actualDataMailmerge="actualDataMailmerge"
            :entityElementDocument.sync="entityElementDocument"
            @placeSelected="placeSelected"
            :isOpenMiddlePanel="isOpenMiddlePanel"
            @actualDataChanged="actualDataChanged"
          />
          <Spreadsheet
            v-if="typeShort === 'sheet'"
            :file="file"
            :reportId="reportId"
            :getData.sync="getData"
            @getFileName="getFileName"
            @defaultData="defaultData"
            :getDataMailmerge.sync="getDataMailmerge"
            @actualData="actualData"
            @actualDataMailmerge="actualDataMailmerge"
            :entityElementDocument.sync="entityElementDocument"
            @activeLoading="activeLoading"
            @deactivateLoading="deactivateLoading"
            :entityElement.sync="entityElement"
            @placeSelected="placeSelected"
          />
        </div>
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DocumentEditor from '../syncfusion/DocumentEditor'
import Spreadsheet from '../syncfusion/Spreadsheet'
import TemplateName from '@/components/edit-document/TemplateName'
import LeftPanel from '@/components/edit-document/LeftPanel'
import MiddlePanel from '@/components/edit-document/MiddlePanel'
import UnsavedChanges from '@/components/modals/UnsavedChanges'
import isEqual from 'lodash.isequal'

export default {
  name: 'NewTemplateStepTwo',
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isDownload: {
      type: Boolean,
      default: false
    },
    isReport: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MiddlePanel,
    LeftPanel,
    TemplateName,
    DocumentEditor,
    Spreadsheet,
    Spreadsheet,
    UnsavedChanges
  },
  data() {
    return {
      type: null,
      typeShort: null,
      isOpenMiddlePanel: false,
      modelId: '',
      reportId: '',
      fileName: '',
      getData: false,
      getDataMailmerge: false,
      isSubmit: false,
      sfdt: null,
      doc: null,
      entityElement: null,
      entityElementDocument: null,
      place: {},
      getName: false,
      redirect: true,
      tenMinutes: 600 * 1000,
      content: {
        title: 'Enregistrer les modifications',
        description: 'Souhaitez-vous enregistrer les modifications apportées à ce document avant de quitter la page ?'
      },
      defaultDataFile: null,
      isEvent: false
    }
  },
  computed: {
    ...mapGetters('documents', [
      'getErrorSendingModel',
      'getErrorSendingReport'
    ]),
    ...mapGetters('users', ['getCurrentUser']),
    ...mapGetters('templates', ['getEntityList', 'getShowUnsavedChanges']),
    getModelId() {
      return this.modelId
    },
    getNewEntityList() {
      const newArr = []
      const entityList = this.getEntityList
      for (const prop of entityList) {
        const newObj = {}
        newObj.entityCode = prop.name.toUpperCase()
        newObj.entityLabel = prop.name
        newObj.entityData = []
        for (const field of prop.fields) {
          const objFields = {}
          objFields.dataCode = field.code
          objFields.dataLabel = field.text
          objFields.dataIsPlaced = field.isPlaced
          objFields.dataPage = []
          if (field.pages) {
            for (const num of field.pages) {
              objFields.dataPage.push(String(num))
            }
          }
          newObj.entityData.push(objFields)
        }
        newArr.push(newObj)
      }
      return newArr
    }
  },
  created() {
    if (this.$route.name === 'EditDocument') return // hide autosave for document

    this.$options.interval = setInterval(this.autoSave, this.tenMinutes)
  },
  methods: {
    ...mapActions('documents', [
      'putModel',
      'putModelUpload',
      'putReport',
      'putReportUpload',
      'uploadModelToS3',
      'setReportStatus',
      'fetchMailMerge',
      'setS3Url',
      'getS3url'
    ]),
    ...mapActions('templates', ['setShowNewFields', 'setShowUnsavedChanges']),
    ...mapActions('documents', ['setMailmergeStatus']),
    autoSave() {
      if (!this.getCurrentUser.userRights.rightModifyModel) return

      this.redirect = false
      this.getName = true
    },
    getExtension(filename) {
      const parts = filename.split('.')
      return parts[parts.length - 1]
    },
    openMiddlePanel() {
      this.isOpenMiddlePanel = !this.isOpenMiddlePanel
      this.$emit('openMiddlePanel')
    },
    getFileName(name) {
      this.fileName = name
      this.getData = true
    },
    getFileNameMailmerge(name) {
      this.fileName = name
      this.getDataMailmerge = true
    },
    actualData(sfdt, doc = null) {
      this.getData = false
      this.sfdt = sfdt
      this.doc = doc
    },
    actualDataChanged(sfdt, doc = null) {
      // This is commented because the actual solution to know if the document has changed 
      // has to make a call to document.saveAsBlob for every chararcter, and it's slow.
      // See DocumentEditor.vue -> changed()
      // const _isEqual = isEqual(sfdt, this.defaultDataFile)
      // this.$emit('dataFileChanged', !_isEqual)
      
      this.$emit('dataFileChanged', true)
    },
    async actualDataMailmerge(sfdt, doc = null) {
      this.getDataMailmerge = false
      this.sfdt = sfdt
      this.doc = doc
      this.submitForReportMailmerge()
    },
    activeLoading() {
      this.isSubmit = true
    },
    deactivateLoading() {
      this.isSubmit = false
    },
    async onSubmitMain(e) {
      this.isEvent = e && e.type === 'submit'
      this.isOnSubmit = true
      this.$emit('clickOnSubmit')
    },
    async onSubmit(e) {
      this.isEvent = e && e.type === 'submit'
      if (this.redirect) this.isSubmit = true
      this.isOnSubmit = true
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    entityElementForDocument(data) {
      this.entityElementDocument = data
    },
    placeSelected(data) {
      this.place = data
    },
    async submitForModel() {
      const payload = this.getShowUnsavedChanges.payload
      if (this.getShowUnsavedChanges.status) {
        this.setShowUnsavedChanges({ status: null, payload: {} })
      }

      const data = {
        modelName: this.fileName,
        modelPlaceholders: this.getNewEntityList
      }

      const s3UrlObject = await this.putModelUpload({ modelId: this.modelId })

      await this.uploadModelToS3({
        s3UrlObject,
        file: this.sfdt,
        type: this.type,
        doc: this.doc
      })
      this.putModel({ modelId: this.modelId, data })

      if (this.getErrorSendingModel.length || !this.redirect) {
        this.stopAutoSave()
        return
      }

      if (this.isEvent) {
        this.$router.push('/templates')
        return
      }
      payload.next()
    },
    async submitForReportMailmerge() {
      // this.getDataMailmerge = true
      this.setMailmergeStatus('ONGOING')

      if (this.typeShort != 'sheet') {
        const s3UrlObject = await this.putReportUpload({
          reportId: this.reportId
        })
        await this.uploadModelToS3({
          s3UrlObject,
          file: this.sfdt,
          type: this.type,
          doc: this.doc
        })
      }

      this.fetchMailMerge(this.reportId).then(i => {
        this.setS3Url({
          type: this.file.typeShort,
          s3Url: i.s3Url,
          s3UrlOriginal: i.s3UrlOriginal,
          name: this.file.name
        })
      })
      this.getDataMailmerge = false
    },
    async submitForReport(redirect = true) {

      const payload = this.getShowUnsavedChanges.payload
      if (this.getShowUnsavedChanges.status) {
        this.setShowUnsavedChanges({ status: null, payload: {} })
      }

      this.getData = true

      const data = {
        reportName: this.fileName
      }

      // Save file to S3
      if (this.file.typeShort == 'document') {
        const s3UrlObject = await this.putReportUpload({
          reportId: this.reportId
        })
        await this.uploadModelToS3({
          s3UrlObject,
          file: this.sfdt,
          type: this.type,
          doc: this.doc
        })
      }

      // Save reportData
      this.putReport({ reportId: this.reportId, data })

      if (this.getErrorSendingReport.length || !redirect) {
        this.stopAutoSave()
        return
      }

      if (this.isEvent) {
        this.$router.push('/documents')
        return
      }

      payload.next()
    },
    stopAutoSave() {
      this.redirect = true
      this.getName = false
    },
    saveUnsavedChanges() {
      this.getName = true
    },
    defaultData(sfdt, doc = null) {
      this.defaultDataFile = sfdt
    },
    nameChanged(status) {
      this.$emit('nameFileChanged', status)
    }
  },
  watch: {
    sfdt: {
      immediate: true,
      handler: function(newSfdt) {
        const _isEqual = isEqual(newSfdt, this.defaultDataFile)
        this.$emit('dataFileChanged', !_isEqual)
        if (this.isOnSubmit) {
          if (!this.isReport) {
            this.submitForModel()
          } else {
            this.submitForReport()
          }
        }
      }
    },
    file: {
      immediate: true,
      async handler(newFile) {
        if (newFile) {
          if (newFile.reportStatus && newFile.reportStatus.length) {
            this.setReportStatus({ status: newFile.reportStatus })
          }
          this.modelId = newFile.modelId
          this.reportId = newFile.reportId
          this.type = newFile.type
          if (newFile.typeShort === 'DOC' || newFile.typeShort === 'DOCX') {
            this.typeShort = 'document'
          } else {
            this.typeShort = 'sheet'
          }
        }
      }
    },
    getErrorSendingModel: function(message) {
      if (message && message.length) {
        this.showNotify(message)
        this.isSubmit = false
      }
    },
    getErrorSendingReport: function(message) {
      if (message && message.length) {
        this.showNotify(message)
        this.isSubmit = false
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.$options.interval)
    this.setShowNewFields(false)
  }
}
</script>
