<template>
  <div class="q-page-middle">
    <div class="q-page-middle--item edit-entity">
      <p class="q-page-middle--item__title">
        {{ getTypeShort && getEdit ? 'Modifier l\'entité' : 'Ajouter une entité' }}
      </p>
      <div
        v-if="getTypeShort && getEdit"
        class="q-page-middle--item q-page-middle--item__select"
      >
        <div class="form-container form-container__height-auto">
          <q-form class="template-name fd-column">
            <div
              :class="{
                'template-name--select__title': true,
                'template-name--select__error':
                  getSelectEntity() === '' ? true : false
              }"
            >
              {{ getEntityName }} est remplacé par :
            </div>
            <q-select
              :class="{
                field__error: getSelectEntity() === '' ? true : false
              }"
              :value="getSelectEntity()"
              :options="getDocumentEntities.entities"
              option-label="entityName"
              option-value="entityName"
              @input="setNewEntityData($event)"
            />
          </q-form>
        </div>
      </div>
      <div v-else class="q-page-middle--item q-page-middle--item__select">
        <div class="form-container form-container__height-auto">
          <q-form class="template-name fd-column">
            <div
              :class="{
                'template-name--select__title': true,
                'template-name--select__error':
                  getSelectEntity() === '' ? true : false
              }"
            >
              La nouvelle entité sera :
            </div>
            <q-select
              :class="{
                field__error: getSelectEntity() === '' ? true : false
              }"
              :value="getSelectEntity()"
              :options="getDocumentEntities.entities"
              option-label="entityName"
              option-value="entityName"
              @input="setNewEntityData($event)"
            />
          </q-form>
        </div>
      </div>
    </div>
    <div class="q-page-middle--item q-page-middle--item__select">
      <p class="q-page-middle--item__title">
        Liste des informations
      </p>
      <div class="form-container form-container__height-auto">
        <q-form class="template-name fd-column">
          <div class="template-name--select__title">
            Catégories d'informations
          </div>
          <q-select
            v-model="entityData.placeholder"
            :options="getListPlaceholders"
            option-label="categoryName"
            option-value="categoryName"
            @click.native="_setSingleEntityPlaceholder($event)"
            class="custom-select"
          >
          </q-select>
        </q-form>
      </div>
    </div>
    <div class="header header__disabled">
      <q-form @submit.stop.prevent>
        <q-input
          v-model="entityData.search"
          debounce="500"
          filled
          placeholder="Rechercher une information"
          class="search search-placeholders"
        >
          <template v-slot:before>
            <img src="../../assets/icons/filter-search.svg" alt="" />
          </template>
          <template v-slot:append>
            <q-icon name="close" @click="entityData.search = ''" />
          </template>
        </q-input>
      </q-form>
    </div>
    <div class="selects-fields">
      <div
        class="w-100"
        v-for="(field, index) in setFieldsForCategory"
        :key="index"
      >
        <template v-if="field.isYearable === false">
          <q-checkbox
            :key="index"
            type="checkbox"
            :value="entityData.fields"
            :val="field.dataCode"
            @click.native="selectChexbox(field.dataCode)"
          >
            <div class="item-field-content item-field-content__custom">
              <div>
                <div
                  class="item-field-content__title item-field-content__title--combine"
                >
                  {{ field.dataLabel }}
                </div>
                <div class="item-field-content__sub-title">
                  {{ field.dataValue }}
                </div>
                <q-tooltip v-if="isShowTooltip(field.dataLabel)">
                  {{ field.dataLabel }}</q-tooltip
                >
              </div>
            </div>
          </q-checkbox>
        </template>
        <div
          class="revenues-block"
          v-if="field.isYearable && field.allYears && field.allYears.length > 0"
          @click="openRevenue(field.dataCode)"
        >
          <span
            :class="{
              'icon-vector': true,
              'icon-vector__open': openRevenueCode === field.dataCode
            }"
          >
            <template v-if="openRevenueCode !== field.dataCode">
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L7 7L1 0.999999"
                  stroke="#424552"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>

            <template v-else-if="openRevenueCode === field.dataCode">
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7L13 1"
                  stroke="#424552"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </span>
          <span
            class="item-field-content__title item-field-content__title--combine"
          >
            {{ field.dataLabel }}
          </span>
          <q-tooltip v-if="isShowTooltip(field.dataLabel)">
            {{ field.dataLabel }}</q-tooltip
          >
        </div>
        <div class="revenue-options">
          <q-checkbox
            :key="`${field.value}_${possible.yearValue}`"
            v-for="possible in field.allYears"
            v-show="
              field.isYearable &&
                field.allYears &&
                field.allYears.length > 0 &&
                openRevenueCode === field.dataCode
            "
            type="checkbox"
            :value="entityData.fields"
            :val="`${field.dataCode}__DY__${possible.yearValue}`"
            @click.native="
              selectChexbox(`${field.dataCode}__DY__${possible.yearValue}`)
            "
          >
            <template v-slot>
              <div>
                <div class="item-field-content item-field-content__custom">
                  <div
                    class="item-field-content__title item-field-content__title--combine"
                  >
                    <p class="possible-field-content__title">
                      {{ possible.yearValue }}
                    </p>
                    <p class="possible-field-content__desc">
                      {{ possible.dataValue }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </q-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MiddlePanel',
  data() {
    return {
      documentEntityName: null,
      isOpenRevenues: false,
      openRevenueCode: null,
      entityData: {
        name: null,
        id: null,
        placeholder: null,
        fields: [],
        status: null,
        listFields: [],
        search: null,
        searchOfCategories: false
      }
    }
  },
  created() {
    if (!this.getEdit) {
      this.setEntityStatus('add')
    }
    this.setEntity()
    this.setEntityListDublicate(this.getEntityList)
  },
  computed: {
    ...mapGetters('templates', [
      'getEntityList',
      'getShowNewFields',
      'getNewEntityFields',
      'getSingleEntity',
      'getEdit',
      'getListPlaceholders',
      'getDocumentEntities',
      'getReportDataResponse',
      'getIndexEditingEntity',
      'getDocumentType',
      'getEntityStatus'
    ]),
    getEntityName() {
      if (typeof this.entityData.name === 'string') {
        return this.entityData.name
      } else if (typeof this.entityData.name === 'object') {
        return this.entityData.name.entityName
      } else {
        return null
      }
    },
    searchReactive() {
      return this.entityData.search
    },
    getSingleEntity() {
      return JSON.parse(
        JSON.stringify(this.$store.getters['templates/getSingleEntity'])
      )
    },
    getListPlaceholders() {
      return JSON.parse(
        JSON.stringify(this.$store.getters['templates/getListPlaceholders'])
      )
    },
    setFieldsForCategory() {
      let sortingRes = []
      if (this.entityData.placeholder) {
        let res = this.getListPlaceholders.filter(
          i => i.categoryName === `${this.entityData.placeholder.categoryName}`
        )
        this.entityData.placeholder = res[0]
        sortingRes = res[0].categoryLabels

        // .map(item => {
        //   return {
        //     ...item,
        //     label: item.dataLabel,
        //     value: item.dataCode
        //   }
        // })
      }

      if (this.searchReactive !== null) {
        sortingRes = sortingRes.filter(i =>
          i.dataLabel.toLowerCase().includes(this.searchReactive.toLowerCase())
        )
        return sortingRes
      } else {
        return sortingRes
      }
    },
    getTypeShort() {
      return this.getDocumentType === 'document'
    }
  },
  methods: {
    ...mapActions('templates', [
      'setEntityListDublicate',
      'setIdInEntityItem',
      'setEntityId',
      'setShowNewFields',
      'setReportData',
      'clearOldFields',
      'clearSingleEntityFields',
      'setFieldsEntity',
      'setSingleEntityPlaceholder',
      'fetchListPlaceholdersByEntityId',
      'setEntityStatus'
    ]),
    setEntity() {
      this.entityData.name = this.getSingleEntity.name
      // debugger
      if (this.getEdit) {
        this.entityData.fields = []
        const a = this.getListPlaceholders.filter(i =>
          i.categoryLabels.some(
            a => a.dataCode === this.getSingleEntity.fields[0]?.code
          )
        )
        // this.entityData.placeholder = a[0]
        if (a && a[0]?.length) {
          this.entityData.placeholder = a[0] // || ['All']
        } else {
          const defaultCategory = this.getListPlaceholders.find(
            i => i.categoryName === 'All'
          )
          this.entityData.placeholder = defaultCategory
        }
        const newFields = []
        for (const prop of this.getSingleEntity.fields) {
          if (prop.dataYear === '0' || prop.dataYear == 'MOST_RECENT') {
            const value = prop.value || prop.dataCode
            newFields.push(value)
          } else {
            const code = prop.code || prop.dataCode
            const value = `${code}__DY__${prop.dataYear}`
            newFields.push(value)
          }
        }
        this.entityData.fields = newFields
        // this.entityData.fields
      } else {
        this.entityData.name = this.getSingleEntity.name
        const defaultCategory = this.getListPlaceholders.find(
          i => i.categoryName === 'All'
        )
        this.entityData.placeholder = defaultCategory
      }
      const entityData = {
        entityId: this.getSingleEntity?.id,
        entityName: this.getSingleEntity?.name
      }
      if (entityData.entityId !== null) {
        this.setNewEntityData(entityData)
      }
      // this.setNewEntityData(entityData)
    },
    getSelectEntity() {
      const entity = this.getDocumentEntities.entities.find(
        entity => entity.entityName === this.entityData.name
      )
      return entity?.entityName || ''
    },
    setSingleEntityName() {
      const name = this.entityData.name.trimStart()
      this.$set(this.entityData, 'name', name)
      this.$store.commit('templates/setSingleEntityName', this.entityData.name)
    },
    selectChexbox(value) {
      const isIncluded = this.entityData.fields.includes(value)
      if (isIncluded === false) {
        const fields = [...this.entityData.fields]
        fields.push(value)
        this.$set(this.entityData, 'fields', fields)
      }
    },
    setNewEntityData(e) {
      if (e.entityId === undefined) return
      this.entityData.name = e.entityName
      this.setIdInEntityItem([this.getIndexEditingEntity, e.entityId])
      this.setEntityId(e.entityId)
      this.setShowNewFields(true)
      this.setReportData(this.getSingleEntity)
      this.$store.commit('templates/setSingleEntityName', e.entityName)
      this.fetchListPlaceholdersByEntityId(e.entityId)
    },
    _setSingleEntityPlaceholder(e) {
      if (e === undefined) return
      this.setSingleEntityPlaceholder(e.categoryName)
    },
    openRevenue(code) {
      this.openRevenueCode = this.openRevenueCode === code ? null : code
    },
    isShowTooltip(title) {
      if (title.length > 50) {
        return true
      }
      return false
    }
  },
  watch: {
    'entityData.fields': function(newValues, oldValues) {
      if (oldValues.length == 0) return

      const findNewValue = newValues.find(x => !oldValues.includes(x))
      const findOldValue = oldValues.find(x => !newValues.includes(x))

      if (findNewValue) {
        const newArr = [...this.getSingleEntity.fields]
        const codes = findNewValue.split('__DY__')
        if (codes.length === 1) {
          // const isField = newArr.filter(field => field.dataCode === codes[0])
          for (const prop in this.entityData.placeholder.categoryLabels) {
            if (
              findNewValue ===
              this.entityData.placeholder.categoryLabels[prop].dataCode
            ) {
              const item = this.entityData.placeholder.categoryLabels[prop]
              const newObj = {
                text: item.dataLabel,
                value: findNewValue,
                dataId: item.dataId,
                dataConfidence: item.dataConfidence,
                placedStatus: false,
                isPlaced: false,
                pages: [],
                positions: [],
                template: false,
                dataValue: item.dataValue,
                isYearable: item.isYearable,
                dataCode: item.dataCode,
                code: item.code,
                dataYear: '0',
                locked: false
              }
              // newObj.text = this.entityData.placeholder.categoryLabels[
              //   prop
              // ].dataLabel
              // newObj.value = findNewValue
              // newObj.placedStatus = false
              // newObj.isPlaced = false
              // newObj.pages = []
              // newObj.positions = []
              // newObj.template = false
              // newObj.dataValue = item.dataValue
              // newObj.isYearable = this.entityData.placeholder.categoryLabels[
              //   prop
              // ].isYearable
              // newObj.dataCode = this.entityData.placeholder.categoryLabels[
              //   prop
              // ].dataCode
              // newObj.code = this.entityData.placeholder.categoryLabels[
              //   prop
              // ].dataCode
              // newObj.dataYear = '0'
              newArr.push(newObj)
            }
          }
        }

        if (codes.length === 2) {
          const isFieldIndex = newArr.findIndex(
            field => field.dataYear === codes[1]
          )

          const categoryLabels =
            this.entityData.placeholder &&
            this.entityData.placeholder.categoryLabels.find(
              el => el.dataCode === codes[0]
            )
          if (categoryLabels == undefined) return
          if (
            categoryLabels.possibleYears &&
            categoryLabels.possibleYears.length
          ) {
            const possible = categoryLabels.possibleYears.find(possible => {
              const yearValue = codes[1]
              return possible.possibleYearCode == yearValue
            })

            let newObj = {
              text: `${categoryLabels.dataLabel} - ${possible.possibleYearCode}`,
              value: findNewValue,
              isPlaced: false,
              isYearable: categoryLabels.isYearable,
              dataCode: categoryLabels.dataCode,
              dataYear: possible.dataYear || '0',
              template: false,
              dataId: possible.dataId,
              dataValue: possible.dataValue,
              dataConfidence: possible.dataConfidence,
              locked: false
            }

            if (isFieldIndex >= 0) {
              newArr[isFieldIndex] = { ...newArr[isFieldIndex], ...newObj }
            } else {
              newArr.push(newObj)
            }
          }

          if (categoryLabels.allYears && categoryLabels.allYears.length) {
            const possible = categoryLabels.allYears.find(
              possible =>
                categoryLabels.dataCode === codes[0] &&
                possible.yearValue == codes[1]
            )
            let newObj = {
              text: `${categoryLabels.dataLabel} - ${possible.yearValue}`,
              value: findNewValue,
              isPlaced: false,
              isYearable: categoryLabels.isYearable,
              dataCode: categoryLabels.dataCode,
              dataYear: possible.dataYear || '0',
              template: false,
              dataId: possible.dataId,
              dataValue: possible.dataValue,
              dataConfidence: possible.dataConfidence,
              locked: false
            }

            if (isFieldIndex >= 0) {
              newArr[isFieldIndex] = { ...newArr[isFieldIndex], ...newObj }
            } else {
              newArr.push(newObj)
            }
          }
          this.setFieldsEntity([newArr, true])
        }

        this.setFieldsEntity([newArr, true])
      }
    },
    getNewEntityFields: function(getNewEntityFields) {
      if (getNewEntityFields.length === 0) return
      const { name } = this.$route
      const isEditDocument = ['EditDocument'].includes(name)
      if (isEditDocument) {
        if (this.getNewEntityFields.length) {
          const categoryName =
            this.getNewEntityFields[0]?.dataValues[0]?.sourceDocumentType || ''
          this.entityData.placeholder = categoryName.toUpperCase()
          this._setSingleEntityPlaceholder({
            categoryName: categoryName.toUpperCase()
          })
        }
        const newArr = this.getEntityList[
          this.getIndexEditingEntity
        ].fields.slice()
        for (const prop of getNewEntityFields) {
          const newObj = {
            editable: false,
            locked: false,
            template: false,
            dataCode: prop.dataCode,
            dataLabel: prop.dataLabel,
            dataId: prop.dataValues[0]?.dataId,
            dataValue: prop.dataValues[0]?.dataValue,
            dataConfidence: prop.dataValues[0]?.dataConfidence,
            value: false
          }
          const index = newArr.findIndex(el => el.dataCode === prop.dataCode)
          if (find && find.length >= 0) {
            newArr[index] = newObj
          } else {
            newArr.push(newObj)
          }
        }

        this.getEntityList[this.getIndexEditingEntity].fields = newArr
        this.setFieldsEntity([this.getEntityList, true])
      }
    },
    getSingleEntity: {
      handler: function(val) {
        const newFields = []
        for (const field of val.fields) {
          if (field.dataYear !== '0') {
            newFields.push(`${field.dataCode}__DY__${field.dataYear}`)
          } else {
            newFields.push(field.value || field.dataCode)
          }
        }
        this.entityData.fields = newFields
      },
      deep: true
    }
  },
  destroyed() {
    this.setShowNewFields(false)
    this.setEntityStatus('read')
  }
}
</script>
