<template>
  <div
    :class="{
      'new-template': true,
      'edit-document': true,
      finished: getReportStatus !== 'READY'
    }"
  >
    <q-page-container>
      <q-page>
        <fragment v-if="file">
          <StepTwo
            :file="file"
            :isEdit="true"
            :isDownload="true"
            :is-report="true"
            @dataFileChanged="dataFileChanged"
            @nameFileChanged="nameFileChanged"
            @clickOnSubmit="clickOnSubmit"
          />
        </fragment>
        <LoadingOrEmpty v-else :loading="getLoadingReport" />
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'
import { mapActions, mapGetters } from 'vuex'
import StepTwo from '@/components/edit-document/StepTwo.vue'
import isEqual from 'lodash.isequal'

export default {
  name: 'EditDocument',
  components: { StepTwo, LoadingOrEmpty },
  data() {
    return {
      file: null,
      dataChanged: false,
      nameChanged: false,
      isOnSubmit: false
    }
  },
  async created() {
    this.$store.commit('templates/setDocumentType', 'document')
    const { reportId } = this.$route.params
    const response = await this.fetchReport({ reportId })

    if (this.getErrorReport.length) {
      this.showNotify(this.getErrorReport)
      return
    }

    let result
    if (response.reportType === 'EXCEL') {
      const url =
        response.modelStatus === 'FIRST_UPLOAD'
          ? response.s3UrlOriginal
          : response.s3Url

      result = await fetch(url).then(res => res)
    } else {
      result = await fetch(response.s3Url).then(res => res.json())
    }

    this.file = {
      result,
      reportId: response.reportId,
      reportStatus: response.reportStatus,
      reportName: response.reportName,
      name: response.reportName,
      modelName: response.modelName,
      modelId: response.modelId,
      typeShort: response.reportType
    }

    await this.fetchReportDone()
  },
  computed: {
    ...mapGetters('documents', [
      'getLoadingReport',
      'getReport',
      'getErrorReport',
      'getReportStatus',
      'getDeleteStatus'
    ]),
    ...mapGetters('templates', [
      'getShowNewFields',
      'getEdit',
      'getEntityList',
      'getSingleEntity',
      'getDocumentType',
      'newReportData',
      'getReportDataResponse',
      'getReportName',
      'getEntityId',
      'getOldLabel',
      'getEntityListDublicate',
      'getPlacedStatus',
      'getDocFocused',
      'getErrorEntityName',
      'getShowUnsavedChanges'
    ])
  },
  methods: {
    ...mapActions('documents', ['fetchReport', 'fetchReportDone']),
    ...mapActions('templates', ['setShowUnsavedChanges']),
    dataFileChanged(status) {
      this.dataChanged = status
    },
    nameFileChanged(status) {
      this.nameChanged = status
    },
    clickOnSubmit() {
      this.isOnSubmit = true
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    }
  },
  beforeDestroy() {
    this.file = null
  },
  beforeRouteLeave(to, from, next) {
    const isFileEXCEL = this.file.typeShort === 'EXCEL'
    if (
      this.isOnSubmit ||
      (this.getShowUnsavedChanges.status === null && !isFileEXCEL)
    ) {
      this.isOnSubmit = false
      next()
      return
    }
    let _isEqual = false
    if (this.getEntityListDublicate && this.getEntityListDublicate.length) {
      _isEqual = isEqual(this.getEntityList, this.getEntityListDublicate)
    }
    if (!_isEqual || this.dataChanged || this.nameChanged || isFileEXCEL) {
      const payload = {
        to,
        from,
        next
      }
      
      // Check if document is being destroyed
      if (this.getDeleteStatus == false) {
        this.setShowUnsavedChanges({ status: true, payload })
      }
      else {
        next()
      }
    } else {
      next()
    }
  }
}
</script>
